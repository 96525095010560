@media (min-width: 768px)and (max-width: 1023px){.tlg-hidden-sm {
  display: none !important;
}}@media (min-width: 768px){.tlg-fullwidth {
  --margin-left: calc(-1 * ((100vw - var(--container) + var(--padding-container) + 1px) / 2 + ((var(--grid-offset-col, 1) - 1) * (var(--container) / var(--grid-size) - 1px))));
  --margin-right: calc(-1 * ((100vw - var(--container) + var(--padding-container) + 1px) / 2 + ((var(--grid-size) - var(--grid-col) - (var(--grid-offset-col, 1) - 1)) * (var(--container) / var(--grid-size) - 1px))));
}

:root {
  --container: 72rem;
}

.tlg-grid {
  --grid-size: 12;
}

.tlg-grid {
  grid-template-columns: repeat(var(--grid-size), 1fr);
}

.tlg-col-sm-1 {
  --grid-col: 1;
}

.tlg-offset-col-sm-1 {
  --grid-offset-col: 1;
}

.tlg-col-sm-2 {
  --grid-col: 2;
}

.tlg-offset-col-sm-2 {
  --grid-offset-col: 2;
}

.tlg-col-sm-3 {
  --grid-col: 3;
}

.tlg-offset-col-sm-3 {
  --grid-offset-col: 3;
}

.tlg-col-sm-4 {
  --grid-col: 4;
}

.tlg-offset-col-sm-4 {
  --grid-offset-col: 4;
}

.tlg-col-sm-5 {
  --grid-col: 5;
}

.tlg-offset-col-sm-5 {
  --grid-offset-col: 5;
}

.tlg-col-sm-6 {
  --grid-col: 6;
}

.tlg-offset-col-sm-6 {
  --grid-offset-col: 6;
}

.tlg-col-sm-7 {
  --grid-col: 7;
}

.tlg-offset-col-sm-7 {
  --grid-offset-col: 7;
}

.tlg-col-sm-8 {
  --grid-col: 8;
}

.tlg-offset-col-sm-8 {
  --grid-offset-col: 8;
}

.tlg-col-sm-9 {
  --grid-col: 9;
}

.tlg-offset-col-sm-9 {
  --grid-offset-col: 9;
}

.tlg-col-sm-10 {
  --grid-col: 10;
}

.tlg-offset-col-sm-10 {
  --grid-offset-col: 10;
}

.tlg-col-sm-11 {
  --grid-col: 11;
}

.tlg-offset-col-sm-11 {
  --grid-offset-col: 11;
}

.tlg-col-sm-12 {
  --grid-col: 12;
}

.tlg-offset-col-sm-12 {
  --grid-offset-col: 12;
}

:root {
  --fontH1: 4rem;
  --fontH2: 2.4rem;
  --fontH3: 2.4rem;
  --fontH4: 2.4rem;
  --fontH5: 1.8rem;
  --fontH6: 1.6rem;
}

.tlg-grid .tlg-game,
.tlg-grid .tlg-event {
  margin-bottom: 4rem;
}

.tlg-detail-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}}